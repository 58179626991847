
:root {
  --main-color: #232323;
  --second-color: #696969;
  --third-color: #a0a0a0;
  --selected: #ff2c2c;
  --back-color: #bbbbbb34;
}

body {
  background-color: var(--main-color);
}

.body {
  width: 100%;
  height: 100%;
  background-color: #000000;
  display: flex;
  justify-content: center;
}

.Demo__container {
  width: 300px;
  height: 100%;
  background-color: var(--second-color);
  display: flex;
  flex-wrap: wrap;
  align-content: start;
  justify-content: center;
  border-radius: 20px;
  gap: 10px;
}

.Demo__some-network__share-button {
  cursor: pointer;
  margin: 5px;
}

.Demo__some-network__share-button:hover:not(:active) {
  opacity: 0.75;
}


@keyframes rotate360 {
  to { transform: rotate(360deg); }
}
.loadingImg { 
  animation: 2s rotate360 infinite linear;
  aspect-ratio: 1 / 1;
  opacity: 0.5;
  height: 40px;
}

.wrapper {
  background-color: rgba(255, 255, 255, 0.079);
  margin: auto;
  position: absolute;
  top: 0px;
  width: 710px;
  height: 100%;
  padding-inline: 35px;
  transition: all .5s;
  align-content: center;


p {
  color: white;
  display: inline-block;
  width: 62%;
  text-align: right;
}
a {
  color: white;
  display: inline-block;
}

.title {
  margin: 0px;
  width: inherit;
  padding: 10px;
  color: white;
  display: block;
  text-align: center;
  font-size: 60px;
}

.userdata {
  display: inline-block;
  width: 60%;
}

.userdata button {
  border: 0px;
  font-size: 20px;
  color: white;
  background-color: rgba(255, 255, 255, 0.196);
  border-radius: 10px;
  width: 80%;
  margin-inline: 10%;
  margin-top: 15px;
  height: 40px;
}

.google {
  float: right;
  color: white;
  font-size: 30px;
  width: 30%;
  margin: 20px;
}

.google p {
  width:auto;
  text-align: center;
  display: block;
}

.google button {
  border-radius: 10px;
  font-size: 20px;
  color: white;
  padding-left: 60px;
  background-color: rgba(255, 255, 255, 0.196);
  height: 70px;
  width: 210px;
  border: 0px;
}

.google button img {
  position: absolute;
  margin-left: -50px;
  margin-top: 5px;
  width: 30px;
  aspect-ratio: 1 / 1;
}

.form {
  position: relative;
  margin-block: 10px;
  margin-inline: 20px;
  height: 60px;
  overflow: hidden;
}

.form input {
  width: 100%;
  height: 100%;
  color: #fff;
  padding-top: 10px;
  border: none;
  background-color: transparent;
}
.form label {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-bottom: 1px solid rgb(158, 158, 158);
}
.form label::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-bottom: 3px solid rgb(255, 255, 255);
  transform: translateX(-100%);
  transition: all 0.3s ease;
}

.content-name {
  position: absolute;
  bottom: 0px;
  left: 0px;
  color: rgb(158, 158, 158);
  padding-bottom: 5px;
  transition: all 0.3s ease;
}
.form input:focus {
  outline: none;
}
.form input:focus + .label-name .content-name,
.form input:valid + .label-name .content-name {
  transform: translateY(-100%);
  font-size: 14px;
  left: 0px;
  color: #ffffff;
}
.form input:focus + .label-name::after{
  transform: translateX(0%);
}
}

hr {
  height: 1px;
  border: 0;
  background-color: rgba(255, 255, 255, 0.3);
  display: block;
  margin: 15px;
}

.hr2 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.link {
  display: block;
  width: 100%;
  padding-left: 20px;
}

#linkname {
  color: white;
  display: inline-block;
}

#linkhref {
  color: rgb(0, 132, 255);
  display: inline-block;
}

#deletelink {
  color: white;
  font-weight: bold;
  display: inline-block;
  background-color: transparent;
  border: none;
}

#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  padding: 16px;
  position: fixed;
  z-index: 10;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;} 
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;} 
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}


::-webkit-scrollbar {
  width: 0px;
}

#overlay {
  position: fixed;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  background-color: transparent;
  z-index: 4;
  visibility: hidden;
}

/* profile */

#register, #account {
  position: fixed;
  top: 10px;
  right: 10px;
  width: max-content;
  z-index: 5;
}

#register a {
  display:inline-block;
  margin: 5px;
  padding: 8px;
  font-size: 20px;
  color: white;
  background-color: #ffffff00;
  border-radius: 10px;
  text-decoration: none;
  transition: all .25s;
}

#register a:hover {
  background-color: rgb(98, 98, 98);
}

#account img {
  position: absolute;
  top: 5px;
  right: 10px;
  left: unset;
  aspect-ratio: 1/1;
  height: 50px;
  border-radius: 50%;
}

#account img.open {
  top: 10px;
  left: 10px;
  right: unset;
  aspect-ratio: 1/1;
  border-radius: 50%;
}

#account div {
  display: none;
  border-radius: 10px;
  background-color: #51565A;
  width: max-content;
  min-width: 175px;
  padding-top: 10px;
  padding-bottom: 5px;
  color: white;
}

#account a {
  display: block;
  margin: 5px;
  padding: 5px;
  font-size: 15px;
  border-radius: 10px;
  color: white;
  transition: all .25s;
  background-color: transparent;
  text-decoration: none;
}

#profileclose {
  position: absolute;
  right: 5px;
  top: 5px;
  color: rgba(255, 255, 255, 0.438);
  background-color: transparent;
  border: none;
}

#account a:hover {
  background-color: #ffffff32;
}

#username {
  font-size: 25px;
  padding: 0px;
  margin-left: 80px;
  margin-right: 30px;
  margin-top: 20px;
  margin-bottom: 0px;
}

/* sidenav */

.sidenav {
  width: 3px;
  position: fixed;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  padding-top: 60px;
  z-index: 5;
  top: 10px;
  left: 70px;
  bottom: 10px;
  overflow-x: hidden;
  background-color: var(--second-color);
  transition: .75s;
}

.sidenav.open {
  width: 275px;
  left: 10px;
}

.notification-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notification-text:hover {
  -webkit-line-clamp: 5;
  line-clamp: 5;
}

.sidenav_button {
  margin: 8px;
  border-radius: 15px;
  font-size: 20px;
  color: rgb(255, 255, 255);
  display: block;
  z-index: 6;
  padding-left: 50px;
  white-space: nowrap;
  text-align: left;
  transition: all .25s;
  text-decoration: none;
}

.sidenav_button img {
  position: fixed;
  left: 25px;
  width: 25px;
  aspect-ratio: 1 / 1;
}

#menu_button {
  position: fixed;
  left: 20px;
  top: 20px;
  border-radius: 30%;
  height: 32px;
  background-color: #ffffff00;
  border-width: 0px;
  aspect-ratio: 1 / 1;
  z-index: 5;
  transition: all .25s;
}

#menu_button:hover {
  background-color: rgb(155, 155, 155);
  height: 35px;
}

#menu_button img {
  visibility: hidden;
  position: absolute;
  top: 0px;
  left: 10%;
  width: 80%;
  height: 100%;
}

.bar1, .bar2, .bar3 {
  width: 20px;
  margin: 2px;
  height: 4px;
  border-radius: 5px;
  background-color: #efefef;
  transition: 0.4s;
}

.change .bar1 {
  transform: translate(0, 6px) rotate(-45deg);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  transform: translate(0, -6px) rotate(45deg);
}

.sidenav_button:hover {
  font-size: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.logo {
  position: fixed;
  font-size: 30px;
  margin: 0px;
  top: 15px;
  width: 200px;
  left: 60px;
  color: white;
  text-align: right;
  padding: 0px;
}

.search {
  position: fixed;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 15px;

  img {
    height: 15px;
    opacity: .5;
    transform: translate(30px,0);
  }

  input {
    border-radius: 20px;
    width: 20%;
    min-width: 150px;
    padding-left: 35px;
    height: 30px;
    border: none;
  }

  input::-webkit-search-cancel-button {
    appearance: none;
    cursor: pointer;

    height: 12px;
    width: 12px;
    opacity: .5;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("./Images/Close.png");
  }
}

.urlcontrol {
  position: fixed;
  top: 20px;
  height: 35px;
  left: 300px;
}

.urlcontrol button {
  height: 100%;
  color: white;
  font-weight: bold;
  background-color: transparent;
  aspect-ratio: 1 / 1;
  border: none;
  border-radius: 50%;
  padding: 7px;
  font-size: 20px;
}

.urlcontrol button:hover {
  background-color: rgba(255, 255, 255, 0.251);
}

#undo {
  transform: rotate(180deg);
}

.menu_icons {
  position: fixed;
  top: 70px;
  left: 20px;
  bottom: 0px;
  width: 225px;
}

.menu_icons a {
  width: 100%;
  margin-top: 10px;
  display: block;
  height: fit-content;
}

.menu_icons a img {
  width: 32px;
  margin-top: 10px;
  display: block;
  aspect-ratio: 1 / 1;
}

/* Page content */
main {
  
  container-type: inline-size;
  position: fixed;
  top: 75px;
  left: 90px;
  bottom: 10px;
  right: 10px;
  transition: all .75s;
  overflow-y: scroll;
}

main.open {
  left: 300px;
}

.albumsList,.artistsList {
  width: 100%;
  padding: 10px;
  overflow: hidden;
  flex-wrap: wrap;
  display: flex;
  gap: 15px;
  justify-content: start;
  max-height: 225px;
}

.album {
  width: 175px;
}

.albumcover {
  display: block;
  overflow: hidden;
  aspect-ratio: 1 / 1;
  width: 100%;
  border-radius: 10px;
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.editAlbum {
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.481);
  transition: .3s;
}

.album:hover .editAlbum {
  opacity: 1;
}

.albumTitle {
  margin-block: 5px;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.track {
  margin-inline: 15px;
  padding-block: 5px;
  position: relative;
  width: stretch;
  height: 40px;
  border-radius: 5px;
  align-content: center;
}

.track:hover {
  background-color: #ffffff33;
}

.deleteTrack {
  height: 50%;
  object-fit: contain;
  margin-right: 10px;
  padding: 5px;
  background-color: rgb(154, 0, 0);
  border-radius: 10px;
}

.deleteTrack:hover {
  background-color: red;
}

.container {
  height: 100%;
  object-fit: fill;
  display: flex;
  align-items: center;
}

#tracknumber {
  color: white;
  width: 20px;
  font-weight: bold;
  margin-right: 10px;
}

#trackcover {
  aspect-ratio: 1 / 1;
  border-radius: 5px;
  height: 100%;
}

#tracksettings {
  display: block;
  margin-left: 10px;
  margin-right: 10px;
  width: 65%;
  height: 100%;
}

#tracknameinput {
  display: block;
  height: 30%;
  width: 100%;
  padding-right: 0px;
  background-color: transparent;
  border: none;
  border-radius: 0px;
  border-bottom: solid var(--third-color) 1px;
  margin: 0px;
}

.tracksource {
  display: none;
}

.tracksourcelabel {
  background-color: #51565A;
  border: solid rgba(255, 255, 255, 0.457) 1px;
  width: 10%;
  height: 100%;
  border-radius: 10px;
  margin-left: 10px;
  transition: .1s;
}

.tracksourcelabel:hover {
  background-color: #737a7f;
}

.tracksourcelabel img {
  height: 58%;
  display: block;
  margin: auto;
  margin-top: 2%;
}
.tracksourcelabel p {
  color: var(--third-color);
  display: block;
  text-align: center;
  font-size: 12px;
  height: 40%;
  padding: 0px;
  width: 100%;
  margin: 0px;
  margin-top: 1px;
}

select, input {
  padding: 5px;
  margin: 5px;
  background-color: #51565A;
  color: white;
  border: solid 1px rgba(255, 255, 255, 0.494);
  border-radius: 5px;
}

.artist {
  margin-inline: 0px;
  width: 175px;
  height: 230px;
  display: inline-block;
}

.artistcover {
  display: block;
  overflow: hidden;
  aspect-ratio: 1 / 1;
  width: 100%;
  border-radius: 50%;
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.editArtist {
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.481);
  transition: .3s;
}

.artist:hover .editArtist{
  opacity: 1;
}

.artistName {
  margin-block: 10px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (max-width: 750px) {
  .wrapper {
      position: absolute;
      background-color: transparent;
      margin: 0px;
      top: 0px;
      left: 0px;
      width: 90%;
      border-radius: 0px;
      height: 100%;
      padding: 25px;
  }
  .userdata {
      display: block;
      width: inherit;
  }
  .google {
      float: none;
      display: block;
      font-size: 30px;
      width: fit-content;
      margin: auto;
  }

  .logo {
    display: none;
  }

  .logoImg {
    display: none;
  }

  .sidenav.open {
    width: unset;
    right: 10px;
    left: 10px;
  }
}

@container (max-width: 750px) {
  #signup {
      visibility: hidden;
  }

  .albumsList {
      max-height: 120px;
  }

  .artistsList {
    max-height: 120px;
  }

  .album {
    width: 75px;
  }

  .artist {
    width: 75px;
    height: unset;
  }

  .artistName {
    font-size: small;
  }

  p,a {
    font-size: small;
  }
}

.titlebtn {
  margin-left: 5px;
  margin-block: 0px;
  display: inline-block;
  color: white;
  border: none;
  font-size: 15px;
  height: 24px;
  border-radius: 10px;
  background-color: #51565A;
  transition: .1s;
}

.titlebtn:hover {
  background-color: #9b9b9b;
}

.spanbtn {
  aspect-ratio: 1/1;
  font-weight: bold;
  background-color: transparent;
  transform: rotate(180deg);
  border-radius: 50%;
  transition: .3s;
  float: right;
  margin-top: 25px;
  margin-right: 15px;
}

.profile {

#description {
  display: block;
  height: 150px;
  width: 98%;
  padding: 12px 20px;
  margin-inline: 1%;
  margin-bottom: 15px;
  box-sizing: border-box;
  border: none;
  color: white;
  border-radius: 10px;
  background-color: #51565A;
  resize: none;
}

#desccount {
  color: rgba(255, 255, 255, 0.459);
  text-align: right;
  padding-right: 15px;
  transform: translate(0px,-10px);
  margin-bottom: -20px;
}

.title {
  color: white;
  display: inline-block;
  font-size: 20px;
  padding: 2px;
  margin-left: 1%;
  margin-bottom: 10px;
  margin-top: 10px;
}

#editframe {
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.form {
  position: relative;
  margin-block: 10px;
  margin-inline: 20px;
  height: 60px;
  overflow: hidden;
}

.form input {
  width: 100%;
  height: 100%;
  color: #fff;
  padding-top: 10px;
  border: none;
  background-color: transparent;
}
.form label {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-bottom: 1px solid rgb(158, 158, 158);
}
.form label::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-bottom: 3px solid rgb(255, 255, 255);
  transform: translateX(-100%);
  transition: all 0.3s ease;
}

.content-name {
  position: absolute;
  bottom: 0px;
  left: 0px;
  color: rgb(158, 158, 158);
  padding-bottom: 5px;
  transition: all 0.3s ease;
}
.form input:focus {
  outline: none;
}
.form input:focus + .label-name .content-name,
.form input:valid + .label-name .content-name {
  transform: translateY(-100%);
  font-size: 14px;
  left: 0px;
  color: #ffffffcf;
}
.form input:focus + .label-name::after{
  transform: translateX(0%);
}

.editform button {
  color: white;
  display: block;
  margin: auto;
  width: 100px;
  border-radius: 20px;
  border: none;
  margin-block: 20px;
  background-color: rgba(62, 62, 62, 0.438);
  height: 30px;
}

#profileeditclose {
  float: right;
  margin: 10px;
  color: rgba(255, 255, 255, 0.438);
  background-color: transparent;
  border: none;
}

#imagepreview {
  display: block;
  margin: auto;
  margin-top: 20px;
  aspect-ratio: 1/1;
  width: 100px;
}

#changeimage {
  display: none;
}

#inputimg {
  display: block;
  margin: auto;
  margin-block: 10px;
  background-color: rgba(255, 255, 255, 0.438);
  border-radius: 10px;
  height: 30px;
  width: 150px;
}

#inputimg img {
  height: 100%;
}

#innereditframe {
  background-color: var(--second-color);
  border-radius: 20px;
  top: 0px;
  width: 300px;
  height: fit-content;
  transition: all .5s;
  z-index: 5;
}

#profileimg {
  display: inline-block;
  border-radius: 50%;
  aspect-ratio: 1/1;
  height: 160px;
  margin-top: 50px;
  margin-left: 30px;
  margin-bottom: 0px;
}

#profilebox {
  display: block;
  height: fit-content;
  width: 100%;
  color: white;
}

#profilebox1 {
  display: inline-block;
  margin-left: 25px;
}

#usernametext {
  display: block;
  font-size: 50px;
  margin-left: 20px;
  margin-bottom: 0px;
}

#followerstext {
  display: inline-block;
  font-size: 15px;
  margin-left: 20px;
  margin-top: 0px;
}

#profilebox2 {
  float: right;
  margin-top: 190px;
  width: 100px;
}

#publictext {
  position: absolute;
  margin: 0px;
  right: 125px;
  font-size: 25px;
}

#editbutton {
  aspect-ratio: 1/1;
  display: inline-block;
  height: 40px;
  margin: 20px;
}

.switch {
  display: inline-block;
  position: relative;
  width: 45px;
  height: 25px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 25px;
}

.slider.round:before {
  border-radius: 50%;
}

@container (max-width: 750px) {

  #profileimg {
      display: block;
      border-radius: 50%;
      aspect-ratio: 1/1;
      height: 160px;
      margin: auto;
      margin-top: 50px;
      margin-bottom: 0px;
  }
  
  #profilebox {
      display: block;
      height: fit-content;
      color: white;
  }
  
  #profilebox1 {
      display: inline-block;
      margin: 0px;
  }

  #profilebox2 {
      display: block;
      margin-top: 10px;
      width: 100%;
  }

  #publictext {
      position: absolute;
      margin: 0px;
      right: unset;
      left: 15px;
      font-size: 25px;
  }

  #editbutton {
      aspect-ratio: 1/1;
      display: inline-block;
      height: 40px;
      margin: 20px;
  }

  .switch {
      float: right;
      margin-right: 15px;
      width: 45px;
      height: 25px;
  }

  .hr2 {
      margin-top: 60px;
  }
}
}

.edit-album {

#description {
  display: block;
  height: 150px;
  width: 98%;
  padding: 12px 20px;
  margin-inline: 1%;
  margin-bottom: 15px;
  box-sizing: border-box;
  border: none;
  color: white;
  border-radius: 10px;
  background-color: #51565A;
  resize: none;
}

.title {
  color: white;
  display: inline-block;
  font-size: 20px;
  padding: 2px;
  margin-left: 1%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.titlebtn {
  margin-left: 5px;
  margin-block: 0px;
  display: inline-block;
  color: white;
  border: none;
  font-size: 15px;
  height: 24px;
  border-radius: 10px;
  background-color: #51565A;
  transition: .1s;
}

.titlebtn:hover {
  background-color: #9b9b9b;
}

#extra {
  display: block;
  height: 150px;
  width: 98%;
  padding: 12px 20px;
  margin-inline: 1%;
  margin-bottom: 15px;
  box-sizing: border-box;
  border: none;
  color: white;
  border-radius: 10px;
  background-color: #51565A;
  resize: none;
}

#editframe {
  display: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

#titleform {
  position: relative;
  height: 90px;
  overflow: hidden;
}

.form input {
  width: 100%;
  height: 100%;
  color: #fff;
  padding-top: 20px;
  font-size: 25px;
  border: none;
  background-color: transparent;
}
.form label {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  font-size: 30px;
  pointer-events: none;
  border-bottom: 1px solid rgb(158, 158, 158);
}
.form label::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-bottom: 3px solid rgb(255, 255, 255);
  transform: translateX(-100%);
  transition: all 0.3s ease;
}

.content-name {
  position: absolute;
  bottom: 0px;
  left: 0px;
  color: rgb(158, 158, 158);
  padding-bottom: 5px;
  transition: all 0.3s ease;
}
.form input:focus {
  outline: none;
}
.form input:focus + .label-name .content-name,
.form input:valid + .label-name .content-name {
  transform: translateY(-100%);
  left: 0px;
  color: #ffffffcf;
}
.form input:focus + .label-name::after{
  transform: translateX(0%);
}

.editform button {
  color: white;
  display: block;
  margin: auto;
  width: 100px;
  border-radius: 20px;
  border: none;
  margin-block: 20px;
  background-color: rgba(62, 62, 62, 0.438);
  height: 30px;
}

#profileeditclose {
  float: right;
  margin: 10px;
  color: rgba(255, 255, 255, 0.438);
  background-color: transparent;
  border: none;
}

#imagepreview {
  display: block;
  margin: auto;
  margin-top: 20px;
  aspect-ratio: 1/1;
  width: 100px;
}

#changeimage {
  display: none;
}

#inputimg {
  display: block;
  margin: auto;
  margin-block: 10px;
  background-color: rgba(255, 255, 255, 0.438);
  border-radius: 10px;
  width: 150px;
}

#innereditframe {
  background-color: var(--second-color);
  border-radius: 20px;
  top: 0px;
  width: 300px;
  height: fit-content;
  transition: all .5s;
  z-index: 5;
}

#albumpreview {
  border-radius: 15px;
  aspect-ratio: 1/1;
  width: 100px;
  height: 100px;
}

#profilebox {
  display: flex;
  padding-left: 20px;
  margin-top: 20px;
  align-items: center;
  width: 100%;
  color: white;
}

#profilebox1 {
  display: inline-block;
  margin-left: 25px;
  width: 50%;
  max-width: 500px;
}

#profilebox2 {
  margin-top: 50px;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  flex-direction: row;
  gap: 10px;
  padding-right: 20px;
}

#savealbum {
  font-size: 18px;
  color: white;
  background-color: #51565A;
  border: none;
  padding: 10px;
  border-radius: 10px;
}

#savealbum:hover {
  background-color: #838c92;
}

#deletealbum {
  background-color: rgb(201, 13, 13);
  float: left;
  margin-right: 20px;
  border: none;
  border-radius: 10px;
  height: 41px;
  aspect-ratio: 1 / 1;
}

#deletealbum:hover {
  background-color: red;
}

#deletealbum img {
  width: 90%;
  height: 80%;
  margin-block: 10%;
  margin-inline: 5%;
}

.leftframe {
  width: 56%;
  margin-inline: 1%;
  float: left;
}

.rightframe {
  background-color: #51565a79;
  width: 38%;
  border-radius: 15px;
  padding-inline: 1%;
  margin-inline: 1%;
  float: right;
}

@container (max-width: 750px) {

  #profileimg {
      display: block;
      border-radius: 50%;
      aspect-ratio: 1/1;
      height: 160px;
      margin: auto;
      margin-top: 50px;
      margin-bottom: 0px;
  }
  
  #profilebox {
      display: block;
      height: fit-content;
      color: white;
  }
  
  #profilebox1 {
      display: inline-block;
      margin: 0px;
  }

  #profilebox2 {
      display: block;
      margin-top: 10px;
      width: 100%;
  }

  #publictext {
      position: absolute;
      margin: 0px;
      right: unset;
      left: 15px;
      font-size: 25px;
  }

  #editbutton {
      aspect-ratio: 1/1;
      display: inline-block;
      height: 40px;
      margin: 20px;
  }

  .switch {
      float: right;
      margin-right: 15px;
      width: 45px;
      height: 25px;
  }

  .hr2 {
      margin-top: 60px;
  }

    
  .leftframe {
      width: 98%;
      display: block;
      margin-inline: 1%;
  }

  .rightframe {
      width: 98%;
      display: block;
      margin-inline: 1%;
}
}
}

.audioControlsPreview {
  height: 50%;
  object-fit: fill;
  margin-block: 5px;
  display: flex;
  padding: 2px;

  .play-icon {
    height: 80%;
    margin: 2px;
    object-fit: contain;
    width: 10%;
    outline: none;
  }

  .trackRange {
    accent-color: white;
    object-fit: fill;
    width: 70%;
    padding: 0px;
    margin: 9px;
    
  }

  .time {
    width: 100px;
    margin: 1px;
    color: white;
  }
}

.about {
  main {
    overflow-y: hidden;
  }


  #descFrame {
    justify-content: center;
    align-items: center;
    text-align: right;
    min-height: 100vh;

    #innerframe {
      width: 50%;
      position: absolute;
      padding: 25px;
      height: 80%;
      z-index: 6;
      background-color: #131313;
      border-radius: 20px;
      overflow-y: scroll;

      .info {
        height: 100px;
        margin-top: 20%;
        position: relative;
      }

      #close {
        float: right;
        position: fixed;
        transform: translate(-5px,-10px);
        color: rgba(255, 255, 255, 0.438);
        background-color: transparent;
        border: none;
      }

      #name {
        margin: 10px;
      }

      #followers {
        margin: 10px;
      }

      #description {
        font-size: 13px;
        display: block;
        white-space-collapse: preserve;
      }
    }
  }

  #description {
    text-align: justify;
    margin: 10px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
    color: var(--third-color);
  }

  #name {
    bottom: 0px;
    position: absolute;
    margin: 20px;
    bottom: 20px;
    font-weight: bold;
    color: white;
    font-size: 40px;
  }

  #followers {
    bottom: 0px;
    position: absolute;
    margin: 20px;
    font-size: 15px;
    bottom: 0px;
    color: white;
  }

  #follow {
    position: absolute;
    z-index: 2;
    right: 20px;
    bottom: 20px;
    color: white;
    background-color: transparent;
    border: solid var(--third-color) 2px;
    font-size: 15px;
    border-radius: 20px;
  }

  #follow:hover {
    border: solid white 2px;
  }

  .title {
    color: white;
    display: inline-block;
    font-size: 20px;
    padding: 2px;
    margin-left: 1%;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .leftframe {
    float: left;
    aspect-ratio: 1.5 / 2;
    max-width: 45%;
    height: 100%;

    div {
      aspect-ratio: 1 / 1;
      width: 100%;
      z-index: 1;
      position: relative;
      background-position: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url("./Images/User.jpg");
      
      div {
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to bottom, transparent, var(--main-color));
      }
    }
  }
  .rightframe {
    overflow-y: scroll;
    position: relative;
    padding: 20px;
    padding-top: 0px;
    height: 100%;
  }
}

.player {
  position: fixed;
  height: 100px;
  z-index: 3;
  bottom: 10px;
  left: 90px;
  right: 10px;
  border-radius: 10px;
  transition: left 0.75s, height 0.2s;
  background-color: var(--second-color);
}

.playerFrame {
  height: 100%;
  width: 100%;
}

.playerFullScreen {
  overflow: hidden;
  background-image: linear-gradient(to bottom, var(--second-color), transparent 80%);
  border-radius: 30px;
  .rightframe {
    float: right;
    height: 100%;
    width: 50%;
  }
  .leftframe {
    float: left;
    margin-left: 2%;
    width: 46%;
    height: 100%;
    overflow-y: scroll;

  }
  .contract {
    position: absolute;
    opacity: 0.3;
    height: 35px;
    top: 15px;
    transform: rotate(90deg);
    right: 15px;
    transition: .2s;
  }
  .contract:hover {
    opacity: .75;
    height: 40px;
  }

  .title {
    margin: 10px;
    margin-top: 10%;
    color: white;
    display: inline-block;
    font-size: 25px;
  }
  .listName {
    font-size: 25px;
    display: inline-block;
    color: var(--third-color);
  }
}

.audioControls {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;

  .album_cover {
    aspect-ratio: 1 / 1;
    float: left;
    border-radius: 5px;
    margin: 10px;
    height: 80%;
  }

  div {
    display: block;
    width: stretch;
    
    margin-top: 25px;
    margin-right: 10px;

    div {
      display: flex;
      position: relative;
      width: 100%;
      justify-content: center;
      margin: 0px;

      img {
        margin-inline: 10px;
      }
    }
  }

  .currentTime {
    position: absolute;
    left: 0;
    margin-top: 10px;
    color: var(--third-color);
  }

  .songName {
    position: absolute;
    left: 0;
    bottom: 5px;
    width: 45%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 20px;
    color: white;
  }

  .play-icon {
    z-index: 3;
  }

  .duration {
    position: absolute;
    right: 0;
    margin-top: 10px;
    color: var(--third-color);
  }

  #seek-slider {
    appearance: none;
    accent-color: rgb(255, 255, 255);
    transition: .1s;
    border: none;
    height: 5px;
    width: 100%;
    z-index: 3;
    margin: 0px;
    padding: 0px;
    margin-block: 10px;
  }

  .span {
    position: absolute;
    opacity: 0.3;
    height: 25px;
    transform: rotate(-90deg);
    right: -15px;
    bottom: 30px;
  }
}

.player:hover {
  height: 105px;
}

.Search {
  .title {
    color: white;
    display: inline-block;
    font-size: 20px;
    padding: 2px;
    margin-left: 1%;
    margin-bottom: 10px;
    margin-top: 20px;
  }
}

.Explore {
  .title {
    color: white;
    display: inline-block;
    font-size: 20px;
    padding: 2px;
    margin-left: 1%;
    margin-bottom: 10px;
    margin-top: 20px;
  }
}

.Home {
  .title {
    color: white;
    display: inline-block;
    font-size: 20px;
    padding: 2px;
    margin-left: 1%;
    margin-bottom: 10px;
    margin-top: 20px;
  }
}

.Library {
  .title {
    color: white;
    display: inline-block;
    font-size: 20px;
    padding: 2px;
    margin-left: 1%;
    margin-bottom: 10px;
    margin-top: 20px;
  }
}

.play-album {
  #albumTitle {
    height: 150px;
    width: 100%;
    display: flex;
    align-items: center;

    img {
      aspect-ratio: 1 / 1;
      border-radius: 10px;
      margin: 10px;
      height: 100%;
    }

    div {
      height: 100%;
      margin-left: 10px;
    }

    .title {
      color: white;
      font-size: 50px;
      margin-bottom: 5px;
      font-weight: bold;
    }
  }
}